<template>
    <div class="card card-custom card-stretch gutter-b" v-loading="loading">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">プロファイルを更新する</h3>
        </div>
      </div>
      <div class="card-body">
        <el-form
          :model="ruleForm"  
          :rules="rules" 
          ref="ruleForm"
          class="form"
          enctype="multipart/form-data"
        >

          <div class="row mb-5">
            <div class="col-md-2">
              <label>メール <span class="required">*</span></label>
            </div>
            <div class="col-md-10">
              <label style="font-size:15px">{{this.currentUser.email}}</label>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
              <label>企業名 <span class="required">*</span></label>
            </div>
            <div class="col-md-10">
              <label style="font-size:15px">{{this.currentUser.name}}</label>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
              <label>その他の情報</label>
            </div>
            <div class="col-md-10">
              <el-form-item prop="description">
                <el-input type="text" v-model="ruleForm.description"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
              <label>以前のパスワード</label>
            </div>
            <div class="col-md-10">
              <el-form-item prop="passwordold">
                <el-input type="password" v-model="ruleForm.passwordold"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
                <label>新しいパスワード</label>
            </div>
            <div class="col-md-10">
                <el-form-item prop="password">
                    <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
                </el-form-item>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
                <label>新しいパスワードを確認</label>
            </div>
            <div class="col-md-10">
                <el-form-item prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
            </div>
          </div>
        

          <!-- <div class="row mb-5">
            <div class="col-md-2">
              <label>電話*</label>
            </div>
            <div class="col-md-10">
              <el-form-item prop="phone">
                <el-input type="text" v-model="ruleForm.phone"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
              <label>Webサイト</label>
            </div>
            <div class="col-md-10">
              <el-form-item prop="website">
                <el-input type="text" v-model="ruleForm.website"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-2">
              <label>会社名</label>
            </div>
            <div class="col-md-10">
              <el-form-item prop="company_name">
                <el-input type="text" v-model="ruleForm.company_name"></el-input>
              </el-form-item>
            </div>
          </div> -->
          
          <el-form-item>
            <el-button @click="goToBack()">戻る</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">更新</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
</template>

<script>

import { UPDATE_PROFILE, CHECK_PASS } from "@/core/services/store/auth.module";
import { mapGetters, mapState, mapActions } from "vuex";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS, BAD_REQUEST } = STATUS_CODE

export default {
    data() {
        var validateCheckPass = (rule, value, callback) => {
            if (this.ruleForm.password != '' && value !== this.ruleForm.password) {
                callback(new Error(this.$t('confirmPassword')));
            } else {
                callback();
            }
        };
        var validatePassword = async (rule, value, callback) => {
            if (value != '' || this.ruleForm.passwordold != '') {
              if (value == '') {
                  callback(new Error(this.$t('validatePassword')));
              } else if (value.length < 8 || value.length > 32) {
                  callback(new Error(this.$t('validatePassword')));
              } else if (value === value.toLowerCase()) {
                  callback(new Error(this.$t('validatePassword')));
              } else if (value === value.toUpperCase()) {
                  callback(new Error(this.$t('validatePassword')));
              } else if (value.search(/[0-9]/) < 0) {
                  callback(new Error(this.$t('validatePassword')));
              } else if (value.search(/[!@#$%^&*]/) < 0) {
                  callback(new Error(this.$t('validatePassword')));
              } else {
                  const response = await this.$store.dispatch(CHECK_PASS, {password: value});
                  if (response.success == true) {
                    callback(new Error(this.$t('passwordExists')));
                  } else {
                    callback();
                  }
              }
            } else {
                callback();
            }
        };
        var validatePasswordOld = async (rule, value, callback) => {
            if (value != '') {
                const response = await this.$store.dispatch(CHECK_PASS, {password: value});
                if (response.success == false) {
                  callback(new Error(this.$t('passwordNotExists')));
                } else {
                  callback();
                }
            } else if (this.ruleForm.password != '' || this.ruleForm.checkPass != '') {
                callback(new Error(this.$t('validatePassword')));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                name: '',
                email: '',
                user_name: '',
                passwordold: '',
                password: '',
                checkPass: '',
                phone: '',
                website: '',
                company_name: '',
                description: ''
            },
            rules: {
                name: [
                    { required: true, message: this.$t('required'), trigger: 'blur' },
                ],
                email: [
                    { required: true, message: this.$t('required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('validateEmail'), trigger: ['blur', 'change'] }
                ],
                user_name: [
                    { required: true, message: this.$t('required'), trigger: 'blur' },
                ],
                phone: [
                    { required: true, pattern:/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, message: this.$t('required'), trigger: 'blur' },
                ],
                passwordold: [
                    { validator: validatePasswordOld, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePassword, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validateCheckPass, trigger: 'blur' }
                ],
                description: [
                  { max: 1048, message: this.$t("maxLength", {name: 'その他の情報', length: '1048'}), trigger: 'blur' }
                ],
            },
            loading: false
        }
    },

    computed: {
        ...mapGetters(["currentUser"]),
    },

    created() {
        this.ruleForm.name = this.currentUser.name
        this.ruleForm.email = this.currentUser.email
        this.ruleForm.user_name = this.currentUser.user_name
        this.ruleForm.phone = this.currentUser.phone
        this.ruleForm.website = this.currentUser.website
        this.ruleForm.company_name = this.currentUser.company_name
        this.ruleForm.description = this.currentUser.description
    },

    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.ruleForm));
                    data.id = this.currentUser.id;
                    data.confirm_password = this.ruleForm.checkPass
                    this.loading = true;
                    const response = await this.$store.dispatch(UPDATE_PROFILE, data);
                    console.log(response);
                    this.loading = false;
                    if (response.code == SUCCESS) {
                        // this.$router.push({name: "dashboard"});
                        this.$router.go(-1);
                        this.notifySuccess(this.$t("updateSuccess"));
                    } else {
                        if (response.code == BAD_REQUEST) {
                            const arr = [];
                            if (response.data.email)    arr.push(response.data.email[0])
                            if (response.data.phone)    arr.push(response.data.phone[0])
                            arr.forEach((value) => {
                                setTimeout(() => {
                                    this.notifyError(value);
                                }, 200)
                            });
                        } else {
                            this.notifyError(this.$t('errorServer'));
                        }
                    }
                }
            });
        },

        goToBack() {
            this.$router.go(-1);
        }
    }
}

</script>